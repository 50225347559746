<template>
  <div class="wrapper">
    <div class="contents">
      <custom-title
        name="延伸服务"
        description="全方位贴心配套服务，助力场馆运营！"
      />
      <div class="columns content-wrapper">
        <div class="column is-5 has-text-right icon-wrapper">
          <svg-icon name="extends" class="sketch" />
        </div>
        <div class="list column is-6 is-offset-1 is-flex-shrink-0">
          <section
            class="item mb-4"
            v-for="(item, index) in configs"
            :key="index"
          >
            <div class="extends-title is-flex is-align-items-center mb-2">
              <svg-icon name="check" class="mr-2 icon"></svg-icon>
              <h4>{{ item.title }}</h4>
            </div>
            <p v-html="item.description" class="description pl-2"></p>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import CustomTitle from "@/components/CustomTitle.vue";
import SvgIcon from "@/components/SvgIcon.vue";
import { defineComponent } from "vue";
export default defineComponent({
  components: { CustomTitle, SvgIcon },
  name: "Extends",
  setup() {
    return {
      configs: [
        {
          title: "短信通知",
          description: "发送上课提醒、课程取消通知等，及时联系会员。",
        },
        {
          title: "公众号消息模板",
          description: `馆主/管理员：学员约课、会员卡统计、拼团结果等通知。<br/>会员：提前120分钟上课提醒、排队结果、课程取消等通知。`,
        },
        {
          title: "专属定制小程序",
          description:
            "大幅提升品牌形象，增加店铺曝光。付费1年的店铺，免费赠送1年的定制小程序。2年/3年同理。",
        },
        {
          title: "1v1技术支持",
          description:
            "快速响应客户，7x12小时专属客服，你的每一份需求和疑问都能得到回应！",
        },
      ],
    };
  },
});
</script>

<style lang="scss" scoped>
@import "../../assets/stylesheets/_helpers.scss";

.wrapper {
  padding-top: 2rem;
}
.contents {
  position: relative;
  margin: auto;
  overflow: hidden;
  max-width: 1400px;
}
.sketch {
  width: auto;
  height: 100%;
}
.extends-title {
  font-size: 18px;
  line-height: 25px;
  h4 {
    color: $title-font-color;
    font-weight: bold;
  }
}
.description {
  font-size: 14px;
  margin-left: 24px;
  color: $sub-font-color;
}

.icon {
  vertical-align: bottom;
}

@include phone() {
  .list {
    padding: 10px 40px;
  }
  .icon-wrapper {
    position: absolute;
    left: 0;
    right: -20px;
    bottom: 0;
    top: 0;
    margin: auto;
    z-index: 0;
    opacity: 0.1;
    transform: scaleX(-1);
    text-align: right;
    .sketch {
      width: 100%;
      height: 100%;
    }
  }
  .content-wrapper {
    position: relative;
  }
}
</style>