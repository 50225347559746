<template>
  <a :href="href" target="_blank" class="has-text-primary more">
    <slot></slot>
  </a>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "CustomLink",
  props: ["href"],
});
</script>

<style lang="scss" scoped>
.more {
  text-decoration: underline;
}
</style>