
import CustomTitle from "@/components/CustomTitle.vue";
import SvgIcon from "@/components/SvgIcon.vue";
import { defineComponent } from "vue";
export default defineComponent({
  components: { CustomTitle, SvgIcon },
  name: "Extends",
  setup() {
    return {
      configs: [
        {
          title: "短信通知",
          description: "发送上课提醒、课程取消通知等，及时联系会员。",
        },
        {
          title: "公众号消息模板",
          description: `馆主/管理员：学员约课、会员卡统计、拼团结果等通知。<br/>会员：提前120分钟上课提醒、排队结果、课程取消等通知。`,
        },
        {
          title: "专属定制小程序",
          description:
            "大幅提升品牌形象，增加店铺曝光。付费1年的店铺，免费赠送1年的定制小程序。2年/3年同理。",
        },
        {
          title: "1v1技术支持",
          description:
            "快速响应客户，7x12小时专属客服，你的每一份需求和疑问都能得到回应！",
        },
      ],
    };
  },
});
