
import { defineComponent, inject, Ref } from "vue";
import { Tooltip } from "ant-design-vue";
import bingcaoQrcode from "@/assets/images/bingcaoQrcode.webp";
import bingcaoMiniProgramQrcode from "@/assets/images/admin-miniprogram-qrcode.jpg";

export default defineComponent({
  name: "FreeTrialButton",
  props: ["buttonClass", "placement"],
  components: { Tooltip },
  setup() {
    const isMobile =  inject<Ref<boolean>>('isMobile')
    const onTrialClick = () => {
      window.open(`https://dd.xiedaimala.com/admin${isMobile?.value ? '' : '-pc'}`)
    }
    return {
      isMobile,
      onTrialClick,
      bingcaoQrcode,
      bingcaoMiniProgramQrcode
    };
  },
});
