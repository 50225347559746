<template>
  <div class="wrapper">
    <span class="text">{{ text }}</span>
    <span class="copy-button" @click="executeCopy" v-if="data.status === 'default'">复制</span>
    <LoadingOutlined v-else-if="data.status === 'copying'"></LoadingOutlined>
    <CheckOutlined v-else-if="data.status === 'success'" :style="{color: '#57BC6C'}"></CheckOutlined>
    <CloseOutlined v-else :style="{color: 'rgba(230,31,26,1)'}"></CloseOutlined>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive } from "vue";
import copyHelper from "@/lib/copyHelper";
import { LoadingOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons-vue";
export default defineComponent({
  name: "Copyable",
  props: ["text"],
  components: { LoadingOutlined, CheckOutlined, CloseOutlined },
  setup(props) {
    const data = reactive<{
      status: "failed" | "copying" | "default" | "success";
      timerId: null | number;
    }>({
      status: "default",
      timerId: null,
    });

    const executeCopy = () => {
      if (data.status !== "default") return;
      data.status = "copying";
      copyHelper(props.text)
        .then(
          () => {
            data.status = "success";
          },
          () => {
            data.status = "failed";
          }
        )
        .finally(() => {
          if (data.timerId !== null) {
            clearTimeout(data.timerId);
          }
          data.timerId = setTimeout(() => {
            data.status = "default";
          }, 3000);
        });
    };

    return {
      data,
      executeCopy,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "../assets/stylesheets/_variables.scss";

.wrapper {
  display: inline-flex;
  align-items: center;
  padding: 0 4px;
  .text {
    margin-right: 1em;
    color: $title-font-color;
    font-size: 14px;
    height: 21px;
    line-height: 21px;
    flex: 1;
  }
  .copy-button {
    color: $remark-font-color;
    font-size: 12px;
    cursor: pointer;
    user-select: none;
    flex-shrink: 0;
  }
}
</style>