<template>
  <h1 style="display: none">冰草约课</h1>
  <navigator></navigator>
  <transition name="fade" mode="out-in">
    <router-view />
  </transition>
  <custom-footer></custom-footer>
</template>

<script lang="ts">
import Navigator from "@/components/Navigator.vue";
import CustomFooter from "@/components/CustomFooter.vue";
import { defineComponent, onMounted, provide, ref } from "vue";
import {throttle} from 'lodash'
import mitt from "mitt";
export default defineComponent({
  components: {
    Navigator,
    CustomFooter,
  },
  setup() {
    const isMobile = ref(document.body.clientWidth < 512)
    const onResize = throttle(() => {
      isMobile.value = document.body.clientWidth < 512
    }, 100)
    onMounted(() => {
      window.addEventListener('resize', onResize)
    })
    provide('isMobile', isMobile)
    provide('$bus', mitt())
  }
});
</script>