<template>
  <div class="wrapper">
    <div class="contents">
      <custom-title name="他们正在使用冰草约课" description="行业使用案例" />
      <tabs :tab-position="isMobile ? 'top' : 'left'">
        <tab-pane
          class="content-wrapper"
          v-for="(config, index) in configs"
          :key="index"
          :tab="config.title"
        >
          <div v-if="isMobile" class="mobile-content">
            <div class="is-flex is-justify-content-space-between mb-5">
              <section class="is-flex is-align-items-center">
                <img
                  :src="
                    require(`@/assets/images/customers/tab-image-${config.categoryName}-shopAvatar.png`)
                  "
                  class="mini"
                />
                <p class="name ml-2">{{ config.exampleShopName }}</p>
              </section>
              <img
                :src="
                  require(`@/assets/images/customers/tab-image-${config.categoryName}-qrcode.png`)
                "
                class="mini qrcode"
              />
            </div>
            <div
              class="is-flex is-justify-content-center is-align-items-center"
            >
              <img
                :src="
                  require(`@/assets/images/customers/tab-image-${config.categoryName}-presentation.png`)
                "
                class="presentation"
              />
            </div>
            <div class="link">
              <custom-link :href="config.more.link">{{
                config.more.text
              }}</custom-link>
            </div>
          </div>
          <div class="is-flex is-justify-content-center" v-else>
            <div class="is-flex is-flex-direction-column mr-6 left-column">
              <custom-link :href="config.more.link">{{
                config.more.text
              }}</custom-link>
              <div class="is-flex-grow-1" style="min-height: 96px"></div>
              <img
                :src="
                  require(`@/assets/images/customers/tab-image-${config.categoryName}-qrcode.png`)
                "
                class="mini qrcode mb-5"
              />
              <img
                :src="
                  require(`@/assets/images/customers/tab-image-${config.categoryName}-shopAvatar.png`)
                "
                class="mini mb-2"
              />
              <p class="name">{{ config.exampleShopName }}</p>
            </div>
            <div
              class="is-flex is-justify-content-center is-align-items-center"
            >
              <img
                :src="
                  require(`@/assets/images/customers/tab-image-${config.categoryName}-presentation.png`)
                "
                class="presentation"
              />
            </div>
          </div>
        </tab-pane>
      </tabs>
    </div>
  </div>
</template>

<script lang="ts">
import CustomTitle from "@/components/CustomTitle.vue";
import { defineComponent, inject } from "vue";
import { Tabs } from "ant-design-vue";
import CustomLink from "@/components/CustomLink.vue";

export default defineComponent({
  components: { CustomTitle, Tabs, TabPane: Tabs.TabPane, CustomLink },
  name: "Customer",
  setup() {
    return {
      isMobile: inject("isMobile"),
      configs: [
        {
          title: "舞蹈类",
          categoryName: "dancing",
          more: { link: "/customers#dance", text: "更多舞蹈类场馆" },
          exampleShopName: "Y-SOUL街舞工作室",
        },
        {
          title: "运动类",
          categoryName: "sport",
          more: { link: "/customers#sport", text: "更多运动类场馆" },
          exampleShopName: "晴天网球俱乐部",
        },
        {
          title: "瑜伽类",
          categoryName: "yoga",
          more: { link: "/customers#yoga", text: "更多瑜伽类场馆" },
          exampleShopName: "澜心瑜伽馆",
        },
        {
          title: "幼教类",
          categoryName: "child",
          more: { link: "/customers#child", text: "更多幼教类场馆" },
          exampleShopName: "海屿星儿童游泳俱乐部",
        },
        {
          title: "艺术类",
          categoryName: "art",
          more: { link: "/customers#art", text: "更多艺术类场馆" },
          exampleShopName: "小筑艺术",
        },
      ],
    };
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/stylesheets/_helpers.scss";

.wrapper {
  padding: 50px 0 80px 0;
}

.contents {
  position: relative;
  margin: auto;
  overflow: hidden;
  max-width: 1400px;
}

.content-wrapper.ant-tabs-tabpane-active {
  height: 100%;
}

img.mini {
  width: 80px;
  height: 80px;
  border-radius: 6px;
  &.qrcode {
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.15));
  }
}

img.presentation {
  max-width: 386px;
  min-height: 330px;
}

p.name {
  font-weight: 500;
  font-size: 14px;
}

.left-column {
  min-width: 200px;
}

@include phone() {
  .wrapper {
    padding: 40px 0 60px 0;
  }

  .mobile-content {
    padding: 0 24px;
  }
  img.presentation {
    width: 100%;
    max-width: none;
    min-height: auto;
  }
  div.link {
    text-align: center;
    margin-top: 20px;
    :deep(.more) {
      font-size: 16px;
    }
  }
}
</style>