<template>
  <div class="wrapper columns" :style="{ backgroundImage: `url(${BackgroundBanner})` }">
    <div
      class="details content is-flex-grow-1 is-flex is-flex-direction-column is-justify-content-center"
    >
      <h2 class="title is-size-2 has-text-white mb-6">场馆约课利器</h2>
      <p class="description is-size-5 has-text-white mb-6 pb-4">
        冰草约课为线下场馆提供线上约课功能，<br />并实现商家后台管理与营销功能。
      </p>
      <div class="buttons-wrapper">
        <free-trial-button
          buttonClass="is-medium is-align-self-flex-start"
          placement="bottom"
        />
        <a
          href="https://docs.bingcaoyueke.com"
          target="_blank"
          class="button is-light is-medium is-align-self-flex-start doc-button"
          >查看完整功能</a
        >
      </div>
    </div>
    <div class="image-wrapper is-flex-shrink-0 mt-6 is-flex-grow-1">
      <img :src="AdminPhone" class="admin-phone" />
      <img :src="UserPhone" class="user-phone" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import AdminPhone from "../../assets/images/banner-2.png";
import UserPhone from "../../assets/images/banner-1.png";
import bingcaoQrcode from "@/assets/images/bingcaoQrcode.webp";
import FreeTrialButton from "@/components/FreeTrialButton.vue";
import BackgroundBanner from "../../assets/images/banner-background.jpg";

export default defineComponent({
  name: "banner",
  components: { FreeTrialButton },
  setup() {
    return {
      AdminPhone,
      UserPhone,
      bingcaoQrcode,
      BackgroundBanner,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/stylesheets/_helpers.scss";

.wrapper {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: calc(100vw / 3.2);
  min-height: 500px;
  padding: 60px 15% 0 15%;
  &.columns {
    margin-bottom: 0;
  }
}

.image-wrapper {
  height: 80%;
  max-height: 80%;
  .admin-phone,
  .user-phone {
    position: relative;
    height: 90%;
  }
  .admin-phone {
    transform: translate(76px, 19px);
    z-index: 1;
  }
}

.buttons-wrapper {
  display: flex;
  align-items: center;
  .doc-button {
    margin-left: 20px;
  }
}

@include phone() {
  .wrapper {
    position: relative;
    margin: 0;
    padding: 100px 10px 0 10px;

    height: 450px;
    min-height: auto;
    .title {
      font-size: 32px !important;
      margin-bottom: 20px !important;
    }
    p.description {
      font-size: 16px !important;
      margin-bottom: 85px !important;
    }
    .button {
      font-size: 16px;
      align-self: center !important;
    }
    .details {
      text-align: center;
      align-items: center;
      position: relative;
      z-index: 1;
    }
    .image-wrapper {
      height: auto;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 200px;
      .admin-phone,
      .user-phone {
        position: relative;
        height: 200px;
      }
      .admin-phone {
        z-index: 1;
        position: absolute;
        left: 50%;
        transform: rotate(-7deg) translateX(-168px);
        top: calc(30% - 50px);
      }
      .user-phone {
        position: absolute;
        left: 50%;
        top: calc(5% - 50px);
        transform: rotate(2deg) translateX(58px);
      }
    }
  }
  .buttons-wrapper {
    flex-direction: column;
    .doc-button {
      margin-left: 0;
      margin-top: 12px;
    }
  }
}
</style>
