<template>
  <tooltip :placement="placement" v-if="!isMobile">
    <template v-slot:title>
      <div class="is-flex pt-2 pb-2">
        <div class="is-flex-shrink-0">
          <img :src="bingcaoQrcode" class="bingcao-qrcode" />
          <p class="description has-text-centered">关注公众号</p>
        </div>
        <div class="is-flex-shrink-0">
          <img :src="bingcaoMiniProgramQrcode" class="bingcao-qrcode" />
          <p class="description has-text-centered">扫码开通 10秒完成</p>
        </div>
        <ul class="is-flex-shrink-0 mt-1 ml-2 entries">
          <li>1.我是店主</li>
          <li>2.网页版/小程序版</li>
          <li>3.场馆约课</li>
          <li>4.免费体验服务</li>
          <li class="description">&nbsp;（试用7天）</li>
        </ul>
      </div>
    </template>
    <button class="button is-primary" :class="buttonClass">免费体验</button>
  </tooltip>
  <button class="button is-primary" :class="buttonClass" @click="onTrialClick" v-else>免费体验</button>
</template>

<script lang="ts">
import { defineComponent, inject, Ref } from "vue";
import { Tooltip } from "ant-design-vue";
import bingcaoQrcode from "@/assets/images/bingcaoQrcode.webp";
import bingcaoMiniProgramQrcode from "@/assets/images/admin-miniprogram-qrcode.jpg";

export default defineComponent({
  name: "FreeTrialButton",
  props: ["buttonClass", "placement"],
  components: { Tooltip },
  setup() {
    const isMobile =  inject<Ref<boolean>>('isMobile')
    const onTrialClick = () => {
      window.open(`https://dd.xiedaimala.com/admin${isMobile?.value ? '' : '-pc'}`)
    }
    return {
      isMobile,
      onTrialClick,
      bingcaoQrcode,
      bingcaoMiniProgramQrcode
    };
  },
});
</script>

<style lang="scss" scoped>
@import "../assets/stylesheets/_variables.scss";
.bingcao-qrcode {
  width: 120px;
  height: 120px;
}
.description {
  font-size: 12px;
  color: $sub-font-color;
}
.entries {
  line-height: 2;
  font-size: 14px;
}
</style>