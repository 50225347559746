<template>
  <div
    class="wrapper is-flex is-flex-grow-1 is-flex-direction-column sub-title"
  >
    <svg-icon :name="icon" class="mb-2 icon" />
    <h3 class="is-size-4 mb-4">{{ name }}</h3>
    <div class="description" v-html="description"></div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import SvgIcon from "./SvgIcon.vue";
export default defineComponent({
  components: { SvgIcon },
  name: "CustomSubTitle",
  props: ["icon", "name", "description", "class"],
});
</script>

<style lang="scss" scoped>
@import "@/assets/stylesheets/_helpers.scss";
.icon {
  width: 36px;
  height: 36px;
}
.description {
  word-break: break-all;
  font-size: 14px;
}

:deep(br.white-space) {
  height: 20px;
  display: block;
  content: " ";
}

@include phone() {
  .wrapper.sub-title {
    text-align: left;
    align-items: center;
    margin: 0;
  }
}
</style>