
import { defineComponent } from "vue";
import AdminPhone from "../../assets/images/banner-2.png";
import UserPhone from "../../assets/images/banner-1.png";
import bingcaoQrcode from "@/assets/images/bingcaoQrcode.webp";
import FreeTrialButton from "@/components/FreeTrialButton.vue";
import BackgroundBanner from "../../assets/images/banner-background.jpg";

export default defineComponent({
  name: "banner",
  components: { FreeTrialButton },
  setup() {
    return {
      AdminPhone,
      UserPhone,
      bingcaoQrcode,
      BackgroundBanner,
    };
  },
});
