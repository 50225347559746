
import CustomSubTitle from "@/components/CustomSubTitle.vue";
import CustomTitle from "@/components/CustomTitle.vue";
import { defineComponent, inject } from "vue";
import configs from "./introductionConfig";
export default defineComponent({
  components: { CustomTitle, CustomSubTitle },
  name: "Introduction",
  setup() {
    const isMobile = inject('isMobile');
    return {
      isMobile,
      configs,
    };
  },
});
