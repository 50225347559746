<template>
  <div id="home" class="home">
    <banner></banner>
    <introduction></introduction>
    <set-up></set-up>
    <extends></extends>
    <customer></customer>
  </div>
</template>

<script lang="ts">
import Banner from "./Banner.vue";
import SetUp from "./SetUp.vue";
import Introduction from "./Introduction.vue";
import Customer from "./Customer.vue";
import { defineComponent } from "vue";
import Extends from "./Extends.vue";

export default defineComponent({
  components: {
    Banner,
    Introduction,
    SetUp,
    Extends,
    Customer,
  },
  name: "Home",
});
</script>
