// Todo: 填充链接
<template>
  <footer class="footer">
    <section class="container">
      <div class="is-3 company-details">
        <svg-icon name="bingcao-icon-gray" class="logo" />
        <a class="ICP" href="https://beian.miit.gov.cn/" target="_blank">备案号: 浙ICP备14041127号-7</a>
        <p class="company">© 杭州饥人谷教育科技有限公司</p>
      </div>
      <div class="is-flex list-wrapper">
        <div class="is-2">
          <p class="title">产品</p>
          <custom-router-link link-class="router-link" to="/" name="功能"></custom-router-link>
          <custom-router-link link-class="router-link" to="/price" name="价格"></custom-router-link>
          <custom-router-link link-class="router-link" to="/customers" name="客户案例"></custom-router-link>
        </div>
        <div class="is-2">
          <p class="title">文档</p>
          <a href="https://docs.bingcaoyueke.com" target="_blank">使用手册</a>
          <a
            href="https://docs.bingcaoyueke.com/%E5%B8%B8%E8%A7%81%E9%97%AE%E9%A2%98/%E4%B8%BA%E4%BB%80%E4%B9%88%E7%BB%99%E4%BC%9A%E5%91%98%E5%8F%91%E5%8D%A1%E5%90%8E%E8%BF%98%E7%BA%A6%E4%B8%8D%E4%BA%86%E8%AF%BE.html"
            target="_blank"
            >常见问题</a
          >
          <a
            href="https://docs.bingcaoyueke.com/%E7%94%A8%E6%88%B7%E6%93%8D%E4%BD%9C%E6%8C%87%E5%8D%97/%E5%9B%A2%E8%AF%BE%E9%A2%84%E7%BA%A6%E6%B5%81%E7%A8%8B.html"
            >学员指南</a
          >
        </div>
        <div class="is-3">
          <p class="title">联系我们</p>
          <tooltip placement="topLeft">
            <template v-slot:title>
              <div class="is-flex is-flex-direction-column">
                <img src="https://static.xiedaimala.com/xdml/image/5939aa7c-d446-47c4-a9c1-ea1e52b10249/MjAyMi02LTE2LTEyLTMwLTctNTk5.jpeg" class="qingqing-qrcode" />
                <copyable text="xiedaimala03"></copyable>
              </div>
            </template>
            <a class="clickable" v-if="isMobile">
              <svg-icon name="wechat"></svg-icon>
              <span>添加微信</span>
            </a>
            <a v-else>微信: xiedaimala03</a>
          </tooltip>
          <a href="tel:15558137209" class="clickable" v-if="isMobile">
            <svg-icon name="phone"></svg-icon>
            <span>拨打电话</span>
          </a>
          <template v-else>
            <a>电话: 15558137209</a>
            <a>(周一至周五 09:30-18:30)</a>
          </template>
        </div>
      </div>
    </section>
  </footer>
</template>

<script lang="ts">
import SvgIcon from "./SvgIcon.vue";
import { Tooltip } from "ant-design-vue";
import Copyable from "./Copyable.vue";
import daquanQrcode from "@/assets/images/daquan.jpg";
import { inject } from "vue";
import CustomRouterLink from '@/views/home/CustomRouterLink.vue';
export default {
  components: { SvgIcon, Tooltip, Copyable, CustomRouterLink },
  setup() {
    return {
      isMobile: inject("isMobile"),
      daquanQrcode
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/stylesheets/_variables.scss";
footer {
  padding: 3em 0;
  background: $footer-background;
}
p,
a, :deep(.router-link) {
  display: block;
  color: $remark-font-color;
  font-size: 12px;
  text-align: left;
  margin-bottom: 10px;
}
a.clickable {
  display: flex;
  align-items: center;
  svg {
    width: 14px;
    height: 14px;
    margin-right: 5px;
  }
}
.company,
.ICP {
  margin-bottom: 0;
}
.title {
  margin-top: 0.5em;
}
section.container {
  display: flex;
  position: relative;
  left: 0;
  right: 0;
  margin: 0 auto;
  max-width: $width-desktop !important;
  padding: 0 100px;
}
.logo {
  width: 160px;
  height: 36px;
  margin-bottom: 2em;
}

.list-wrapper {
  flex: 1;
  justify-content: flex-end;
  .is-2 {
    width: 130px;
  }
  .is-3 {
    width: 210px;
  }
}

.company-details {
  padding: 0.75rem;
}

.qingqing-qrcode {
  width: 150px;
  height: 150px;
}

@media (max-width: $width-desktop) {
  section.container {
    display: flex !important;
    flex-direction: column-reverse !important;
    padding: 0;
    p,
    a, :deep(.router-link) {
      font-size: 14px;
    }
    .list-wrapper {
      justify-content: center;
      .is-2,
      .is-3 {
        width: 25%;
      }
    }
    .company-details {
      .logo {
        margin-bottom: 1rem;
      }
      &,
      & > p {
        text-align: center;
      }
    }
    .ICP {
      text-align: center;
    }
  }
}
</style>
