const copyHelper = (text: string | undefined) => {
  return new Promise<void>((resolve, reject) => {
    const input = document.createElement("input");
    document.body.appendChild(input);
    input.setAttribute("value", text || "");
    input.setAttribute("readonly", "readonly");
    input.select();
    input.setSelectionRange(0, input.value.length);
    const copyResult = document.execCommand("copy")
    document.body.removeChild(input);
    setTimeout(() => {
      if (copyResult) {
        resolve()
      } else {
        reject()
      }
    }, 500)
    
  });
};

export default copyHelper;
