
import { defineComponent, inject, Ref } from "vue";
import SvgIcon from "./SvgIcon.vue";
import { Tooltip } from "ant-design-vue";
import daquanQrcode from "@/assets/images/daquan.jpg";
import Copyable from "./Copyable.vue";
import FreeTrialButton from "./FreeTrialButton.vue";
import CustomRouterLink from "@/views/home/CustomRouterLink.vue";

export default defineComponent({
  name: "NavigatorContent",
  props: {
    mode: {
      type: String,
      required: true,
    },
    menuExpanded: {
      type: Boolean,
    },
  },
  components: {
    SvgIcon,
    Tooltip,
    Copyable,
    FreeTrialButton,
    CustomRouterLink,
  },
  setup() {
    const isMobile = inject<Ref<boolean>>("isMobile");
    return {
      daquanQrcode,
      isMobile,
      onLoginClick: () =>
        window.open(`https://dd.xiedaimala.com/admin${isMobile?.value ? "" : "-pc"}`),
    };
  },
});
