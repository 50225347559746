<template>
  <div class="wrapper" :style="{ backgroundImage: `url('${background}')` }">
    <custom-title
      name="简单几步，助您开设场馆"
      description="免费试用，期间有工作人员随时答疑解惑！"
    />
    <div class="list is-flex has-text-centered">
      <template v-for="(config, index) in configs" :key="index">
        <section class="content">
          <div class="icon-wrapper mb-3">
            <svg-icon :name="config.icon" class="icon"></svg-icon>
            <div class="mobile-indicator">{{index + 1}}</div>
          </div>
          <h4 class="title mb-3">{{ config.title }}</h4>
          <p class="description" v-html="config.description"></p>
        </section>
        <div class="indicator-wrapper" v-if="!isMobile && index !== 3">
          <div class="divider"></div>
          <div class="indicator"><span class="arrow"></span></div>
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import CustomTitle from "@/components/CustomTitle.vue";
import { defineComponent, inject, Ref } from "vue";
import Background from "@/assets/images/set-up-background.png";
import MobileBackground from "@/assets/images/set-up-background-mobile.png";
import SvgIcon from "@/components/SvgIcon.vue";
export default defineComponent({
  components: { CustomTitle, SvgIcon },
  name: "SetUp",
  setup() {
    const isMobile = inject<Ref<boolean>>('isMobile')
    return {
      isMobile,
      background: isMobile && isMobile.value ? MobileBackground : Background,
      configs: [
        {
          icon: "set-up-1",
          title: "一键开店  免费体验",
          description: `不到10秒即可开设自己的小店，5天<b class="has-text-primary">免费</b>试用！`,
        },
        {
          icon: "set-up-2",
          title: "线上指导  场馆搭建",
          description: `体验期间也有<b class="has-text-primary">一对一指导</b>操作，助力场馆的前期设置！`,
        },
        {
          icon: "set-up-3",
          title: "快速上手  数据录入",
          description: `<b class="has-text-primary">操作简单，文档详细</b>，快速迁移客户、员工数据！`,
        },
        {
          icon: "set-up-4",
          title: "升级维护  持续服务",
          description: `<b class="has-text-primary">长期优化更新</b>系统，设立VIP客户小群，实时解答疑问！`,
        },
      ],
    };
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/stylesheets/_helpers.scss";

.wrapper {
  position: relative;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 40px 0 20px 0;
}
.list {
  position: relative;
  max-width: 1300px;
  margin: auto;
  .icon-wrapper {
    display: inline-block;
    width: 90px;
    height: 90px;
    background-color: white;
    border-radius: 50%;
    overflow: hidden;
    .icon {
      width: 90%;
      height: 100%;
    }
  }
  .content {
    flex: 1;
    h4.title {
      font-weight: bold;
      font-size: 14px;
      color: $title-font-color;
    }
    p.description {
      position: relative;
      margin: auto;
      max-width: 220px;
      padding: 0 10px;
    }
  }
  .indicator-wrapper {
    position: relative;
    margin-left: -5%;
    margin-right: -5%;
    margin-top: 40px;
    padding: 0 5%;
    height: fit-content;
    .divider {
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      z-index: -1;
      border-bottom: 1px dashed $remark-font-color;
    }
    .indicator {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
      border-radius: 50%;
      width: 22px;
      height: 22px;
      background: #ffffff;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
      .arrow {
        position: relative;
        left: -1px;
        vertical-align: bottom;
        width: 7px;
        height: 7px;
        border-top: 2px solid #ccc;
        border-right: 2px solid #ccc;
        transform: rotate(45deg);
        transform-origin: center;
      }
    }
  }
}

@include phone() {
  .wrapper {
    background-size: 100% 560px;
  }
  .list {
    flex-wrap: wrap;
  }
  .content {
    width: 50%;
    flex: auto !important;
    flex-shrink: 0 !important;
    p.description {
      padding: 0 15px !important;
      font-size: 14px;
    }
  }
  .icon-wrapper {
    position: relative;
    overflow: visible !important;
    margin-bottom: 37px !important;
    .mobile-indicator {
      border-radius: 50%;
      background-color: $primary;
      position: absolute;
      z-index: -1;
      width: 30px;
      height: 30px;
      left: 0;
      right: 0;
      bottom: -17px;
      margin: 0 auto;
      padding-top: 15px;
      line-height: 1;
      font-size: 12px;
      color: white;
    }
  }
}
</style>