<template>
  <router-link
    :to="to"
    :active-class="activeClass"
    :alt="name"
    :class="linkClass"
    @click="onLinkClick"
    ><span class="link">{{
      name
    }}</span></router-link
  >
</template>

<script lang="ts">
import { Emitter } from "mitt";
import { defineComponent, inject } from "vue";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "CustomRouterLink",
  props: ["to", "linkClass", "activeClass", "name"],
  setup(props) {
    const eventBus = inject<Emitter>('$bus')
    const router = useRouter()
    const onLinkClick = (e: Event) => {
      e.preventDefault()
      router.push(props.to)
      eventBus && eventBus.emit('linkClick', props.to)
    }
    return {
      onLinkClick
    };
  },
});
</script>

<style lang="scss" scoped>
</style>