import { createApp, App as AppType } from "vue";
import App from "./App.vue";
import router from "./router";
import "./assets/stylesheets/_bulma_modified.scss";
import "./assets/stylesheets/_antd_modified.scss";
import "./assets/stylesheets/_reset.scss";
import "./assets/stylesheets/_v-tooltip.scss";
const importAll = (requireContext: __WebpackModuleApi.RequireContext) =>
  requireContext.keys().forEach(requireContext);

try {
  importAll(require.context('./assets/icons', true, /\.svg$/));
} catch (error) {
  console.log(error);
}

const app: AppType = createApp(App);

app
  .use(router)
  .mount("#app");
