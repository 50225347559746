const isNodeEnvironment = typeof window === 'undefined'
const wx = isNodeEnvironment ? {} : require('weixin-js-sdk')
const requestHost = 'https://dd-api.xiedaimala.com/wechat_vender_sign_package'
const shareLinkRecord = new Map()

const getMemberConfig = async () => {
  const url = encodeURIComponent(window.location.href.split('#')[0])
  if (shareLinkRecord.has(url)) {
    return shareLinkRecord.get(url)
  }
  try {
    const config = await fetch(requestHost + `?url=${url}`, { mode: 'cors' })
      .then(response => response.json())
      .then(result => result['sign_package'])
    shareLinkRecord.set(url, config)
    return config
  } catch (e) {
    console.error(e)
  }
}

const wxConfig = (signPackage: any, config: any) => {
  if (!signPackage) return Promise.reject()
  wx.config({
    appId: signPackage.appId,
    url: signPackage.url,
    timestamp: signPackage.timestamp,
    nonceStr: signPackage.nonceStr,
    signature: signPackage.signature,
    jsApiList: [
      'updateAppMessageShareData',
      'updateTimelineShareData',
      'onMenuShareTimeline',
      'onMenuShareAppMessage'
    ],
  });
  wx.error((e: any) => {
    console.log(e)
  })
  wx.ready(() => {
    const shareConfig = {
      title: config.title,
      desc: config.desc || '',
      link: config.link,
      imgUrl: config.imgUrl,
      success: config.onSuccess,
      fail: config.onFailed,
    };
    wx.updateAppMessageShareData(shareConfig);
    wx.updateTimelineShareData(shareConfig);
    wx.onMenuShareTimeline(shareConfig);
    wx.onMenuShareAppMessage(shareConfig);
  });
};

const wechatShareHelper = async (config: any) => {
  const signPackage = await getMemberConfig()
  wxConfig(signPackage, config)
}

const getShareTitle = () => {
  return '冰草约课 - 微信轻松约课，场馆安心教学'
}

const getShareDesc = () => {
  if (typeof document === 'undefined') return
  return '10秒钟搭建微信端瑜伽馆、工作室、健身房约课平台，免费试用7天，支持团体课、小班课、私教课约课，支持开卡、售卡、约课、拼团等功能。'
}

const getShareLink = () => {
  if (isNodeEnvironment) return
  return window.location.href
}

export { getShareTitle, getShareDesc, getShareLink, wechatShareHelper }