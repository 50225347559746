<template>
  <div class="custom-title-wrapper mt-3">
    <h2 class="title has-text-centered">{{ name }}</h2>
    <p
      class="description has-text-grey has-text-centered"
      v-html="description"
    ></p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "CustomTitle",
  props: ["name", "description"],
});
</script>

<style lang="scss" scoped>
@import "@/assets/stylesheets/_helpers.scss";

.custom-title-wrapper {
  margin-bottom: 60px;
  .title {
    font-size: 36px;
    margin-bottom: 12px;
  }
  .description {
    font-size: 14px;
  }

  @include phone() {
    .title {
      font-size: 26px;
      margin-bottom: 10px;
    }
    .description {
      font-size: 14px;
    }
  }
}
</style>