<template>
  <navigator-content
    :menuExpanded="menuExpanded"
    :mode="mode"
    @toggleMenu="toggleMenu"
  ></navigator-content>
</template>

<script lang="ts">
import { Emitter } from "mitt";
import {
  defineComponent,
  inject,
  nextTick,
  onMounted,
  onUnmounted,
  ref,
} from "vue";
import NavigatorContent from "./NavigatorContent.vue";
export default defineComponent({
  components: {
    NavigatorContent,
  },
  setup() {
    const pathName = ref(location.pathname);
    const isThemeShouldChangeOnScroll = () => pathName.value === "/";
    const menuExpanded = ref(false);
    const mode = ref(isThemeShouldChangeOnScroll() ? "transparent" : "white");
    const eventBus = inject<Emitter>("$bus");

    const scrollHandler = () => {
      if (menuExpanded.value || !isThemeShouldChangeOnScroll()) return;
      const scrollTop =
        document.body.scrollTop || document.documentElement.scrollTop;
      const newMode = scrollTop > 0 ? "white" : "transparent";
      if (newMode === mode.value) return;
      else
        requestAnimationFrame(() => {
          mode.value = newMode;
        });
    };

    const onLinkClick = async (path?: string) => {
      if (!path) return;
      await nextTick();
      pathName.value = path;
      await nextTick();

      menuExpanded.value = false;
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });

      await nextTick();
      if (!isThemeShouldChangeOnScroll()) {
        mode.value = "white";
      } else {
        scrollHandler();
      }
    };

    const toggleMenu = () => {
      menuExpanded.value = !menuExpanded.value;
      if (menuExpanded.value) {
        mode.value = "white";
      } else {
        scrollHandler();
      }
    };

    onMounted(() => {
      document?.addEventListener("scroll", scrollHandler);
      eventBus?.on("linkClick", onLinkClick);
    });
    onUnmounted(() => {
      document?.removeEventListener("scroll", scrollHandler);
      eventBus?.off("linkClick", onLinkClick);
    });
    return {
      toggleMenu,
      menuExpanded,
      mode,
      onLinkClick,
    };
  },
});
</script>
