
import CustomTitle from "@/components/CustomTitle.vue";
import { defineComponent, inject } from "vue";
import { Tabs } from "ant-design-vue";
import CustomLink from "@/components/CustomLink.vue";

export default defineComponent({
  components: { CustomTitle, Tabs, TabPane: Tabs.TabPane, CustomLink },
  name: "Customer",
  setup() {
    return {
      isMobile: inject("isMobile"),
      configs: [
        {
          title: "舞蹈类",
          categoryName: "dancing",
          more: { link: "/customers#dance", text: "更多舞蹈类场馆" },
          exampleShopName: "Y-SOUL街舞工作室",
        },
        {
          title: "运动类",
          categoryName: "sport",
          more: { link: "/customers#sport", text: "更多运动类场馆" },
          exampleShopName: "晴天网球俱乐部",
        },
        {
          title: "瑜伽类",
          categoryName: "yoga",
          more: { link: "/customers#yoga", text: "更多瑜伽类场馆" },
          exampleShopName: "澜心瑜伽馆",
        },
        {
          title: "幼教类",
          categoryName: "child",
          more: { link: "/customers#child", text: "更多幼教类场馆" },
          exampleShopName: "海屿星儿童游泳俱乐部",
        },
        {
          title: "艺术类",
          categoryName: "art",
          more: { link: "/customers#art", text: "更多艺术类场馆" },
          exampleShopName: "小筑艺术",
        },
      ],
    };
  },
});
