
import CustomTitle from "@/components/CustomTitle.vue";
import { defineComponent, inject, Ref } from "vue";
import Background from "@/assets/images/set-up-background.png";
import MobileBackground from "@/assets/images/set-up-background-mobile.png";
import SvgIcon from "@/components/SvgIcon.vue";
export default defineComponent({
  components: { CustomTitle, SvgIcon },
  name: "SetUp",
  setup() {
    const isMobile = inject<Ref<boolean>>('isMobile')
    return {
      isMobile,
      background: isMobile && isMobile.value ? MobileBackground : Background,
      configs: [
        {
          icon: "set-up-1",
          title: "一键开店  免费体验",
          description: `不到10秒即可开设自己的小店，5天<b class="has-text-primary">免费</b>试用！`,
        },
        {
          icon: "set-up-2",
          title: "线上指导  场馆搭建",
          description: `体验期间也有<b class="has-text-primary">一对一指导</b>操作，助力场馆的前期设置！`,
        },
        {
          icon: "set-up-3",
          title: "快速上手  数据录入",
          description: `<b class="has-text-primary">操作简单，文档详细</b>，快速迁移客户、员工数据！`,
        },
        {
          icon: "set-up-4",
          title: "升级维护  持续服务",
          description: `<b class="has-text-primary">长期优化更新</b>系统，设立VIP客户小群，实时解答疑问！`,
        },
      ],
    };
  },
});
