
import SvgIcon from "./SvgIcon.vue";
import { Tooltip } from "ant-design-vue";
import Copyable from "./Copyable.vue";
import daquanQrcode from "@/assets/images/daquan.jpg";
import { inject } from "vue";
import CustomRouterLink from '@/views/home/CustomRouterLink.vue';
export default {
  components: { SvgIcon, Tooltip, Copyable, CustomRouterLink },
  setup() {
    return {
      isMobile: inject("isMobile"),
      daquanQrcode
    };
  },
};
