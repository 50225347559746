<template>
  <nav :class="'theme-' + mode" class="navbar">
    <div class="navbar-brand">
      <section class="column">
        <svg-icon class="logo" name="bingcao-icon"></svg-icon>
      </section>
      <a
        role="button"
        class="navbar-burger"
        aria-label="menu"
        aria-expanded="false"
        data-target="navbarBasicExample"
        @click="$emit('toggleMenu')"
      >
        <svg-icon
          :name="menuExpanded ? 'arrow' : 'burger'"
          aria-hidden="true"
        ></svg-icon>
      </a>
    </div>

    <div class="navbar-menu" :class="{ 'is-active': menuExpanded }">
      <div class="navbar-start">
        <custom-router-link
          link-class="navbar-item link"
          active-class="has-text-weight-semibold"
          to="/"
          name="功能"
        ></custom-router-link>
        <custom-router-link
          link-class="navbar-item link"
          active-class="has-text-weight-semibold"
          to="/price"
          name="价格"
        ></custom-router-link>
        <custom-router-link
          link-class="navbar-item link"
          active-class="has-text-weight-semibold"
          to="/customers"
          name="客户案例"
        ></custom-router-link>
      </div>

      <div class="navbar-end">
        <div class="contacts is-flex is-align-items-center mr-2">
          <span class="mr-4">联系我们 : </span>
          <tooltip placement="bottom">
            <template v-slot:title>
              <div class="is-flex is-flex-direction-column is-align-items-center">
                <img src="https://static.xiedaimala.com/xdml/image/5939aa7c-d446-47c4-a9c1-ea1e52b10249/MjAyMi02LTE2LTEyLTMwLTctNTk5.jpeg" class="qingqing-qrcode" />
                <copyable text="xiedaimala03"></copyable>
              </div>
            </template>
            <svg-icon name="wechat" class="mr-2"></svg-icon>
          </tooltip>
          <a href="tel:15558137209" v-if="isMobile">
            <svg-icon name="phone" class="mr-4"></svg-icon>
          </a>
          <tooltip placement="bottom" v-else>
            <template v-slot:title>
              <copyable text="15558137209"></copyable>
            </template>
            <svg-icon name="phone" class="mr-4"></svg-icon>
          </tooltip>
        </div>
        <div class="navbar-item">
          <div class="field is-grouped">
            <div
              class="button transparent login-button mr-2"
              @click="onLoginClick"
            >
              登录
            </div>
            <free-trial-button
              buttonClass="is-small free-trial-button"
              placement="bottomRight"
            ></free-trial-button>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script lang="ts">
import { defineComponent, inject, Ref } from "vue";
import SvgIcon from "./SvgIcon.vue";
import { Tooltip } from "ant-design-vue";
import daquanQrcode from "@/assets/images/daquan.jpg";
import Copyable from "./Copyable.vue";
import FreeTrialButton from "./FreeTrialButton.vue";
import CustomRouterLink from "@/views/home/CustomRouterLink.vue";

export default defineComponent({
  name: "NavigatorContent",
  props: {
    mode: {
      type: String,
      required: true,
    },
    menuExpanded: {
      type: Boolean,
    },
  },
  components: {
    SvgIcon,
    Tooltip,
    Copyable,
    FreeTrialButton,
    CustomRouterLink,
  },
  setup() {
    const isMobile = inject<Ref<boolean>>("isMobile");
    return {
      daquanQrcode,
      isMobile,
      onLoginClick: () =>
        window.open(`https://dd.xiedaimala.com/admin${isMobile?.value ? "" : "-pc"}`),
    };
  },
});
</script>

<style lang="scss" scoped>
@import "../assets/stylesheets/_helpers.scss";

nav {
  z-index: $z-index-banner;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  @include pc() {
    transition: background-color 0.3s, border-color 0.3s, color 0.2s;
    border-bottom: 2px solid transparent;
  }
}
.columns {
  margin-top: 0;
  margin-bottom: 0 !important;
  user-select: none;
}
.logo {
  width: 160px;
  height: 36px;
  vertical-align: bottom;
}

.links {
  max-width: 300px;
  > a {
    color: inherit;
  }
}

:deep(.free-trial-button),
.login-button {
  font-size: 16px !important;
}
.button.transparent {
  color: inherit !important;
  border: none;
  outline: none;
  background-color: transparent;
}
.column-right {
  white-space: nowrap;
}
.contacts {
  margin-right: 10px;
  svg {
    cursor: pointer;
  }
}

.qingqing-qrcode {
  width: 150px;
  height: 150px;
}

.theme-transparent {
  background-color: transparent;
  color: white;
}

.theme-white {
  background-color: white;
  color: $sub-font-color;
  @include pc() {
    border-bottom: 2px solid rgb(248, 248, 248);
  }
  @include phone() {
    .navbar-brand {
      border-bottom: 2px solid rgb(248, 248, 248);
    }
  }
}

.link {
  padding: 0 2vw;
}

.navbar-brand {
  padding-left: 3vw;
  position: relative;
  z-index: $z-index-banner;
}

.navbar-end {
  padding-right: 3vw;
  position: relative;
  z-index: $z-index-banner;
}

.navbar-burger {
  &:hover {
    background-color: transparent;
  }
  color: inherit;
  height: auto;
  width: auto;
  padding: 12px;
  svg {
    color: inherit;
  }
}

.button:focus:not(:active) {
  box-shadow: none;
}

@media (min-width: $width-desktop) {
  .navbar-start {
    justify-content: center;
    flex: 1;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
  }
}

@media (max-width: $width-desktop) {
  nav.theme-white {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
    .navbar-brand {
      border-bottom: none;
    }
  }
  .navbar-menu.is-active {
    padding: 0;
  }
  .navbar-item,
  .contacts {
    padding: 15px;
    span.link {
      padding: 0;
    }
  }
  .login-button {
    padding: 0 !important;
    height: auto;
    flex: 1;
    justify-content: flex-start;
  }
  :deep(.free-trial-button) {
    display: none;
  }
}
</style>
