import AssignmentImage from '../../assets/images/assignment.webp'
import UsersImage from '../../assets/images/users.webp'
import CardsImage from '../../assets/images/cards.webp'
import GroupBuyImage from '../../assets/images/groupBuy.webp'

export default [
  {
    title: '轻松排课与管理',
    description: '嵌于微信端，无需下载其他App，商家随时随地操作。',
    subIcon: 'assignment',
    subtitle: '快速排课',
    subDescription: `以周为单位展示排课。<b>课程管理</b>、<b>课表排课</b>、<b>团课预约</b>，<br/>满足商家从课时排课到预约管理的不同需求。<br class="white-space"/>多选日期、复制课表，短短几步即可完成一个月的排课！`,
    image: AssignmentImage
  },
  {
    subIcon: 'users',
    subtitle: '会员管理系统',
    subDescription: `随时在手机端发卡，销卡，约课，排队，请假，上课短信通知，续费提醒等。<br class="white-space"/>每周在公众号发送卡片统计结果，根据不同状态对会员进行筛选，及时与客户沟通。减少客户的流失，提高客户粘性。`,
    image: UsersImage
  },
  {
    title: '多种营销模式',
    description: '不仅是一款约课产品，还是商家的营销帮手！',
    subIcon: 'cards',
    subtitle: '针对场景的售卡方案',
    subDescription: `<div class="card-type">黑金储值卡</div>
    <p>可以设置不同的卡内实际金额和卡片售价，适合开业、店庆、大促等，如：冲5000送1000。</p>
    <div class="card-type">限时限次卡</div>
    <p>可以设置卡次数、适用课程、原价、打折价、截止日期。适合各种场景的促销。</p>
    <div class="card-type">阶梯价私教课程包</div>
    <p>适合私教课程促销，用户可按次随意随时购买，摆脱套餐限制，可配置起售次数。</p>
    `,
    image: CardsImage
  },
  {
    subIcon: 'groupBuy',
    subtitle: '拼团/秒杀',
    subDescription: `通过平台约课学员可以看到所有促销优惠，潜移默化地实现付费转化。活动弹窗展示促销卡片，店铺活动版块、轮播图可以跳转至活动页面。<br class="white-space"/>
    一键分享活动链接或生成活动图片，直接转发到微信聊天或朋友圈，扩大宣传途径。`,
    image: GroupBuyImage
  }
]