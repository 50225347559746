
import { defineComponent, reactive } from "vue";
import copyHelper from "@/lib/copyHelper";
import { LoadingOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons-vue";
export default defineComponent({
  name: "Copyable",
  props: ["text"],
  components: { LoadingOutlined, CheckOutlined, CloseOutlined },
  setup(props) {
    const data = reactive<{
      status: "failed" | "copying" | "default" | "success";
      timerId: null | number;
    }>({
      status: "default",
      timerId: null,
    });

    const executeCopy = () => {
      if (data.status !== "default") return;
      data.status = "copying";
      copyHelper(props.text)
        .then(
          () => {
            data.status = "success";
          },
          () => {
            data.status = "failed";
          }
        )
        .finally(() => {
          if (data.timerId !== null) {
            clearTimeout(data.timerId);
          }
          data.timerId = setTimeout(() => {
            data.status = "default";
          }, 3000);
        });
    };

    return {
      data,
      executeCopy,
    };
  },
});
