<template>
  <div class="outer" v-for="(config, index) in configs" :key="index">
    <div class="section">
      <custom-title
        v-if="config.title"
        :name="config.title"
        :description="config.description"
      ></custom-title>
      <div
        class=""
        :class="
          isMobile
            ? ''
            : `is-flex is-flex-direction-row${index % 2 ? '' : '-reverse'}`
        "
      >
        <custom-sub-title
          :icon="config.subIcon"
          :description="config.subDescription"
          :name="config.subtitle"
        ></custom-sub-title>
        <img :src="config.image" class="image" />
      </div>
    </div>
  </div>
  <div class="has-text-centered">
    <a
      href="https://docs.bingcaoyueke.com"
      target="_blank"
      class="has-text-primary more"
      >更多功能介绍</a
    >
  </div>
</template>

<script lang="ts">
import CustomSubTitle from "@/components/CustomSubTitle.vue";
import CustomTitle from "@/components/CustomTitle.vue";
import { defineComponent, inject } from "vue";
import configs from "./introductionConfig";
export default defineComponent({
  components: { CustomTitle, CustomSubTitle },
  name: "Introduction",
  setup() {
    const isMobile = inject('isMobile');
    return {
      isMobile,
      configs,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "../../assets/stylesheets/_helpers.scss";

.section {
  max-width: 1400px;
  position: relative;
  margin: auto;
}

.outer:nth-of-type(3),
.outer:nth-of-type(2) {
  background: $bg-light-green;
}

:deep(.sub-title) {
  margin: 0 36px;
}

.image {
  max-width: 55%;
  margin: 0 36px;
}

.more {
  text-decoration: underline;
}

:deep(.card-type) {
  font-size: 14px;
  font-weight: bold;
  color: $sub-font-color;
}

@include phone() {
  .section {
    padding: 0 15px 50px;
  }
  .outer:nth-of-type(2),
  .outer:nth-of-type(4) {
    padding-top: 50px;
  }
  .image {
    width: 100%;
    max-width: none;
    margin: 30px 0 10px;
  }
  .description {
    color: $sub-font-color;
  }
  :deep(.card-type) {
    text-align: center;
  }
}
</style>