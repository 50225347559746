import { getShareDesc, getShareLink, getShareTitle, wechatShareHelper } from '@/lib/wechatShareHelper'
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/home/Home.vue'

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/price',
    name: 'Price',
    component: () => import(/* webpackChunkName: "about" */ '../views/price/Price.vue')
  },
  {
    path: '/customers',
    name: 'Customers',
    component: () => import(/* webpackChunkName: "about" */ '../views/customers/Customers.vue')
  }
]

const router = createRouter({
  history: createWebHistory('/'),
  routes
})

router.afterEach(() => {
  setTimeout(() => {
    const title = getShareTitle()
    const desc = getShareDesc()
    const link = getShareLink()
    wechatShareHelper({
      title,
      desc,
      link,
      imgUrl: 'https://jrg-dingdong.oss-cn-hangzhou.aliyuncs.com/dingdong/1/favicon.png'
    })
  }, 200)
})

export default router
